import SiteWrapper from "../components/wrapper.tsx";
import { Helmet } from "react-helmet";
import ogImage from "../assets/images/pagerduty_dxable_og.png";
import { useEffect } from "react";

import MdRenderer from "../components/mdrender";
import * as md from "../components/mdrender.module.sass";
import TitleBlock from "../components/title";

import "./contact-jp@gen.scss";

const GeneratedContactJp = () => {
  useEffect(() => {
    var sc, d;
    try {
      sc = document.createElement("script");
      sc.src =
        "https://digitalstackscorporation-team.myfreshworks.com/crm/sales/web_forms/6bb61a9349bc8c65f0492830f4cf66243f6258d875600dd73990710469babf86/form.js";
      sc.crossOrigin = "anonymous";
      sc.id =
        "fs_6bb61a9349bc8c65f0492830f4cf66243f6258d875600dd73990710469babf86";
      d = document.getElementById("contact-form");
      d.appendChild(sc);

      new MutationObserver(function (mutationsList, observer) {
        mutationsList.forEach((mutation) => {
          if (mutation.addedNodes) {
            var inputElements = document.getElementsByName(
              "contact[custom_field][cf_web_form]"
            );
            if (inputElements.length > 0) {
              inputElements[0].value =
                "https://pagerduty.dxable.com/contact-jp/";
              var parent = inputElements[0].parentNode;
              parent.classList.add("fserv-hidden");
            }
            mutation.addedNodes.forEach((node) => {
              var css = node.querySelector
                ? node.querySelector('link[href*="webforms.css"]')
                : null;
              if (css) {
                observer.disconnect();
                css.addEventListener("load", function () {
                  setTimeout(function () {
                    loop: for (
                      var i = 0;
                      i < document.styleSheets.length;
                      i++
                    ) {
                      var styleSheet = document.styleSheets[i];
                      if (
                        styleSheet.href &&
                        styleSheet.href.includes("webforms.css")
                      ) {
                        var rules = styleSheet.rules;
                        for (var j = 0; j < rules.length; j++) {
                          var rule = rules[j];
                          if (
                            rule.selectorText &&
                            rule.selectorText.includes(".hidden,")
                          ) {
                            var cssText = rule.cssText.replace(
                              ".hidden,",
                              ".fs-webform-container .hidden,"
                            );
                            styleSheet.deleteRule(j);
                            styleSheet.insertRule(cssText, j);
                            break loop;
                          }
                        }
                      }
                    }
                  });
                });
              }
            });
          }
        });
      }).observe(document.body, { childList: true, subtree: true });
    } catch (e) {}

    return () => {
      if (sc && d) d.removeChild(sc);
    };
  }, []);

  return (
    <SiteWrapper isLandingPage={false}>
      <Helmet>
        <title>お問い合わせ - PagerDuty正規代理店 DXable</title>

        <script
          type="text/javascript"
          defer
          src="https://app.secureprivacy.ai/script/639bffa95b4c7ff65172b36d.js"
        ></script>
        <meta
          property="twitter:description"
          content="お問い合わせやサービスデモンストレーションのご要望は、 以下のフォームをご利用ください。"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          name="description"
          content="お問い合わせやサービスデモンストレーションのご要望は、 以下のフォームをご利用ください。"
        />

        <meta
          property="og:title"
          content="お問い合わせ - PagerDuty正規代理店 DXable"
        />

        <meta
          property="og:description"
          content="お問い合わせやサービスデモンストレーションのご要望は、 以下のフォームをご利用ください。"
        />
        <meta
          property="og:url"
          content="https://pagerduty.dxable.com/contact-jp/"
        />
        <meta
          property="twitter:url"
          content="https://pagerduty.dxable.com/contact-jp/"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
        <meta
          property="twitter:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
      </Helmet>

      <TitleBlock
        classNames="md:mb-[80px] mb-[40px]"
        textYellow="Contact Us"
        textGreen="お問い合わせ"
        overview=""
      />

      <div className="contactWrapper">
        <div className="center-1  mb-[-5px] !mt-[80px] !md:w-[85%]">
          <p className="center-para flex-col !mt-0">
            <span className="block mb-[40px]">
              お問い合わせやサービスデモンストレーションのご要望は、
              以下のフォームをご利用ください。
            </span>
            <span>
              ご利用のインターネット環境によりフォームが表示されない場合がございます。
              もしも、入力フォームが表示されない場合は、こちらのメールアドレスへお問い合わせください。info@digitalaccels.com
              <span className="block text-[red]">
                *この項目は入力する必要があります。
              </span>
            </span>
          </p>
        </div>
      </div>

      <div id="contact-form" className="formWrapper mb-[10px]" />

      <div className="contactWrapper">
        <div className="center-1 !mt-0 mb-[20px]">
          <p>
            <a
              target="_blank"
              href="https://corp.digitalstacks.net/privacy-policy"
              className="text-[#4EB346]"
            >
              個人情報保護方針
            </a>
            についてご確認の上、ご同意いただける場合は上記の項目にチェックを入れてご送信ください。
          </p>
        </div>
      </div>
    </SiteWrapper>
  );
};
export default GeneratedContactJp;
